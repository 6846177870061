<template lang="pug">
  v-container
    v-row
      v-col
        v-card(
          outlined
        )
          v-card-title
            v-layout(
              flex
            )
              // - v-text-field.flex-shrink-1.flex-grow-0(
              // -   v-model.trim="search"
              // -   label="Search Orders"
              // -   single-line
              // -   prepend-inner-icon="mdi-magnify"
              // -   hide-details
              // -   filled
              // -   :loading="(search !== null || search !== '') && areOrdersPending"
              // -   rounded
              // -   dense
              // - )
              v-btn.ml-auto(
                color="secondary"
                :to="{ name: 'drawers' }"
              )
                v-icon(
                  left
                ) mdi-plus
                | New Order
          v-data-table.clickable(
            :headers="headers"
            :loading="areOrdersPending"
            no-data-text="No Orders Found"
            :options.sync="options"
            :server-items-length="haveOrdersLoaded ? paginationData.default.mostRecent.total : 0"
            :items="orders"
            @click:row="(item) => $router.push({name: 'order', params: { orderId: item.id }})"
            :footer-props="{ itemsPerPageOptions: [5,10,15] }"
          )
            template(v-slot:item.createdAt="{ item: order }") {{ $day(order.createdAt).format('M/D/YY h:mm A') }}
            template(#body.prepend)
              tr
                td(colspan="100")
                  v-btn(
                    text
                    color="primary"
                    block
                    :to="{ name: 'drawers' }"
                  )
                    v-icon(
                      left
                    ) mdi-plus
                    | Add Order
            template(v-slot:item.action="{ item: order }")
              DownloadOrderButton.pl-0(
                :order="order"
                :text="null"
              )
</template>

<script>
import { useFind } from 'feathers-vuex'
import { computed, ref } from '@vue/composition-api'
import DownloadOrderButton from '@/components/DownloadOrderButton'

export default {
  name: 'Orders',
  components: {
    DownloadOrderButton
  },
  setup (props, context) {
    const { Order } = context.root.$FeathersVuex.api
    // Setups up a reference that the search can v-model
    const search = ref(null)

    // Basic starter datatable attributes
    const options = ref({
      page: 1,
      itemsPerPage: 10,
      sortBy: ['createdAt'],
      sortDesc: [true]
    })

    // Compute the sort based on various datatable properties
    const sortBy = computed(() => {
      var obj = {}
      if (options.value.sortBy && options.value.sortBy.length) {
        obj[options.value.sortBy[0]] = options.value.sortDesc[0] ? -1 : 1
      }
      return obj
    })

    const limit = computed(() => {
      if (options.value.itemsPerPage !== -1) {
        return options.value.itemsPerPage
      } else {
        return 999
      }
    })

    const query = computed(() => {
      // Construct the base query
      const query = {
        $limit: limit.value,
        $skip: options.value.itemsPerPage * (options.value.page - 1),
        $sort: sortBy.value,
        status: 'SUBMITTED'
      }

      return query
    })

    const params = computed(() => {
      const params = { ...query.value }

      if (search.value !== null && search.value !== '') {
        params.name = {
          $regex: '^' + search.value,
          $options: 'gi'
        }
      }

      return { query: params }
    })

    const fetchParams = computed(() => {
      const fetchParams = { ...query.value }

      if (search.value !== null && search.value !== '') {
        fetchParams.name = {
          $iRegexp: '^' + search.value
        }
      }

      return { query: { ...fetchParams, $eager: 'shop' } }
    })

    // Do the actual querying, be sure to remember to paginate
    const {
      items: orders,
      isPending: areOrdersPending,
      paginationData,
      haveLoaded: haveOrdersLoaded
    } = useFind({
      model: Order,
      params,
      fetchParams,
      paginate: true
    })

    return {
      search,
      options,
      sortBy,
      limit,
      orders,
      query,
      params,
      fetchParams,
      areOrdersPending,
      haveOrdersLoaded,
      paginationData
    }
  },
  computed: {
    user () {
      return this.$store.getters['auth/user']
    }
  },
  data: () => ({
    headers: [
      {
        text: 'Date',
        value: 'createdAt'
      },
      {
        text: 'Shop',
        value: 'shop.name'
      },
      {
        text: 'Submitted By',
        value: 'submittedBy'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: 'Action',
        value: 'action'
      }
    ]
  })
}
</script>
